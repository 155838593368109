// Generated by Framer (236a21b)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["CbzOcgWoL", "NyGB4OmVN"];

const serializationHash = "framer-qLv2k"

const variantClassNames = {CbzOcgWoL: "framer-v-ccgiff", NyGB4OmVN: "framer-v-6ko13f"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {Close: "NyGB4OmVN", Menu: "CbzOcgWoL"}

const getProps = ({height, id, tap, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "CbzOcgWoL", vnCwfiSlw: tap ?? props.vnCwfiSlw} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;tap?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, vnCwfiSlw, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "CbzOcgWoL", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTap16f7gog = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (vnCwfiSlw) {const res = await vnCwfiSlw(...args);
if (res === false) return false;}
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-ccgiff", className, classNames)} data-framer-name={"Menu"} data-highlight layoutDependency={layoutDependency} layoutId={"CbzOcgWoL"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={onTap16f7gog} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({NyGB4OmVN: {"data-framer-name": "Close"}}, baseVariant, gestureVariant)}><motion.div className={"framer-1vu5x2b"} data-framer-name={"Bottom"} layoutDependency={layoutDependency} layoutId={"O325UyT_0"} style={{backgroundColor: "var(--token-ebfdfc6f-a0e8-414d-b30e-eeab99d6e438, rgb(158, 163, 191))", borderBottomLeftRadius: 10, borderBottomRightRadius: 10, borderTopLeftRadius: 10, borderTopRightRadius: 10, rotate: 0}} variants={{NyGB4OmVN: {rotate: -45}}}/><motion.div className={"framer-1qcwbfl"} data-framer-name={"Top"} layoutDependency={layoutDependency} layoutId={"vle5Ee0Q0"} style={{backgroundColor: "var(--token-ebfdfc6f-a0e8-414d-b30e-eeab99d6e438, rgb(158, 163, 191))", borderBottomLeftRadius: 10, borderBottomRightRadius: 10, borderTopLeftRadius: 10, borderTopRightRadius: 10, rotate: 0}} variants={{NyGB4OmVN: {rotate: 45}}}/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-qLv2k.framer-1vzw0wg, .framer-qLv2k .framer-1vzw0wg { display: block; }", ".framer-qLv2k.framer-ccgiff { cursor: pointer; height: 40px; overflow: hidden; position: relative; width: 40px; }", ".framer-qLv2k .framer-1vu5x2b { flex: none; height: 2px; left: calc(50.00000000000002% - 20px / 2); overflow: hidden; position: absolute; top: calc(62.50000000000002% - 2px / 2); width: 20px; will-change: var(--framer-will-change-override, transform); }", ".framer-qLv2k .framer-1qcwbfl { flex: none; height: 2px; left: calc(50.00000000000002% - 20px / 2); overflow: hidden; position: absolute; top: calc(37.50000000000002% - 2px / 2); width: 20px; will-change: var(--framer-will-change-override, transform); }", ".framer-qLv2k.framer-v-6ko13f .framer-1vu5x2b, .framer-qLv2k.framer-v-6ko13f .framer-1qcwbfl { top: calc(50.00000000000002% - 2px / 2); }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 40
 * @framerIntrinsicWidth 40
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"NyGB4OmVN":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"vnCwfiSlw":"tap"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerGPiH2eT1y: React.ComponentType<Props> = withCSS(Component, css, "framer-qLv2k") as typeof Component;
export default FramerGPiH2eT1y;

FramerGPiH2eT1y.displayName = "Hamburgar";

FramerGPiH2eT1y.defaultProps = {height: 40, width: 40};

addPropertyControls(FramerGPiH2eT1y, {variant: {options: ["CbzOcgWoL", "NyGB4OmVN"], optionTitles: ["Menu", "Close"], title: "Variant", type: ControlType.Enum}, vnCwfiSlw: {title: "Tap", type: ControlType.EventHandler}} as any)

addFonts(FramerGPiH2eT1y, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})